import React from 'react';
import { Link } from 'react-router-dom';
import './Sitemap.css';

const Sitemap = () => {
  return (
    <div className="sitemap-container">
      <h1>Sitemap</h1>
      <div className="sitemap-vertical">
        <div className="sitemap-section">
          <h2>Main Pages</h2>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about-us">About Us</Link></li>
            <li><Link to="/services">Our Services</Link></li>
            <li><Link to="/pricing">Pricing</Link></li>
            <li><Link to="/contact-us">Contact Us</Link></li>
          </ul>
        </div>
        <div className="sitemap-section">
          <h2>Industries</h2>
          <ul>
            <li><Link to="/industry/bfsi">BFSI</Link></li>
            <li><Link to="/industry/healthcare">Healthcare</Link></li>
            <li><Link to="/industry/utilities">Utilities</Link></li>
            <li><Link to="/industry/retail">Retail</Link></li>
            <li><Link to="/industry/other">Other Industries</Link></li>
          </ul>
        </div>
        <div className="sitemap-section">
          <h2>Company</h2>
          <ul>
            <li><Link to="/partner">Partner With Us</Link></li>
            <li><Link to="/careers">Careers</Link></li>
            <li><Link to="/newsroom">Newsroom</Link></li>
            <li><Link to="/blog">Blog</Link></li>
          </ul>
        </div>
        <div className="sitemap-section">
          <h2>Legal</h2>
          <ul>
            <li><Link to="/terms-of-service">Terms of Service</Link></li>
            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            <li><Link to="/data-security-policy">Data Security Policy</Link></li>
            <li><Link to="/cookie-preferences">Cookie Preferences</Link></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sitemap;
